import React from "react";
import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Divider,
} from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { baseURL } from "../../api/base";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 260,
    width: 300,
    maxHeight: 350,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      maxWidth: 350,
      width: "100%",
    },
  },
  media: {
    width: "100%",
    height: 175,
  },
  content: {
    padding: 15,
    height: "auto",
    textAlign: "left",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: 11,
    margin: 0,
  },
  price: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "none",
    color: "#F50057",
    margin: 0,
  },
  subtitle: {
    fontFamily: "Montserrat",
    color: "#9E9E9E",
    fontSize: 10,
  },
  options: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginTop: 8,
    fontSize: 10,
  },
  location: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginTop: 8,
    fontSize: 10,
  },
  accion: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    position: "absolute",
    top: 10,
    left: 10,
    padding: "2px 5px 2px 5px",
    borderRadius: 5,
    background: "#00B2F2",
    color: "white",
    textTransform: "none",
    fontSize: 10,
  },
}));
export const ListCard = ({
  image,
  accion = "Destacado",
  price = "10000",
  title = "Departamento",
  description = "Acogedor y Moderno",
  area = "500",
  code = "2D - 18",
  location = "Iquique, Cl",
  ciudad,
  pais,
  img_principal,
  precio_de_venta,
  precio_de_alquiler,
  tipo_de_moneda,
  tipo_de_inmueble,
  titulo,
  bano,
  habitaciones,
  superficie_total,
}) => {
  const classes = useStyles();

  return (
    <MuiCard className={classes.root}>
      <CardActionArea>
        <Typography
          className={classes.accion}
          variant="button"
          display="block"
          gutterBottom
        >
          {accion}
        </Typography>
        <CardMedia
          className={classes.media}
          image={
            baseURL + "/storage" + img_principal?.slice(6, img_principal.lenght)
          }
          title={
            baseURL + "/storage" + img_principal?.slice(6, img_principal.lenght)
          }
        />
        <CardContent className={classes.content}>
          <Typography
            className={classes.price}
            variant="button"
            display="block"
            gutterBottom
          >
            {precio_de_venta && Math.round(precio_de_venta)}{" "}
            {precio_de_alquiler && Math.round(precio_de_alquiler)}{" "}
            {tipo_de_moneda && tipo_de_moneda?.nombre}
          </Typography>
          <Typography
            className={classes.title}
            variant="button"
            display="block"
            gutterBottom
          >
            {titulo ? titulo.slice(0, 27) : "Acogedor Espacio"}
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="caption"
            display="block"
            gutterBottom
          >
            {tipo_de_inmueble?.nombre}
          </Typography>
          <Typography
            className={classes.options}
            variant="caption"
            display="block"
            gutterBottom
          >
            {superficie_total ? superficie_total : "500"} m² &nbsp;&nbsp;&nbsp;{" "}
            {habitaciones?.nombre} Dormitorios &nbsp;&nbsp;&nbsp;{" "}
            {bano?.nombre > 1
              ? `${bano?.nombre} Baños`
              : `${bano?.nombre} Baño`}{" "}
          </Typography>
          <Divider />
          <Typography
            className={classes.location}
            variant="caption"
            display="block"
            gutterBottom
          >
            <LocationOn
              color="secondary"
              style={{ fontSize: "14px", position: "absolute" }}
            />
            <span style={{ marginLeft: "20px" }}>
              {ciudad?.nombre + ", " + pais?.nombre}{" "}
            </span>
          </Typography>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};
