import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  styled,
  Checkbox,
  Collapse,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { AlertTitle, Alert } from "@mui/material";
import { useFormik } from "formik";
import request from "../../api";
import * as yup from "yup";
import {
  Instagram,
  Facebook,
  Home,
  RecordVoiceOver,
  WhatsApp,
} from "@material-ui/icons";

import AddToHomeScreen from "./AddToHomeScreen";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    background: "#43484E",
    width: "100%",
    height: "auto",
    padding: "40px 60px",
  },
  title: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "700",
    borderBottom: "5px solid #00B1F4",
  },
  p: {
    color: "white",
    fontFamily: "Montserrat",
    fontSize: "14x",
    marginTop: "10px",
    textAlign: "justify",
  },
  radio: {
    color: "#D9D9D9",
    "&$checked": {
      color: "#00B2F2",
    },
  },
  checked: {},
  list: {
    color: "white",
    fontFamily: "Montserrat",
    fontSize: "14x",
    lineHeight: "25px",
    textDecoration: "none",
    textAlign: "justify",
  },
  contact: {
    position: "absolute",
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "700",
    textAlign: "center",
    lineHeight: "30px",
    top: 30,
    right: 50,
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: 0,
      right: 0,
    },
  },
  contactBtn: {
    background: "#00B1F4",
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
    textAlign: "center",
    padding: 5,
    borderRadius: 10,
    cursor: "pointer",
  },
  containerWs: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  ws: {
    position: "relative",
    marginTop: 20,
    background: "#1A87B0",
    height: "auto",
    width: "80%",
    borderRadius: 20,
    textAlign: "center",
    padding: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 10,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: 14,
    // },
  },
  wsText: {
    background: "#1CB154",
    position: "absolute",
    left: 20,
    borderRadius: "100%",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      left: 3,
    },
  },
  btnws: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "center",
    lineHeight: "30px",
  },
  titleDialog: {
    fontFamily: "Montserrat",
    textAlign: "center",
    textTransform: "uppercase",
  },
}));
const MuiTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#00B2F2",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#00B2F2",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#00B2F2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00B2F2",
    },
  },
});
const links = [
  {
    to: "/",
    name: "Inicio",
  },
  {
    to: "/",
    name: "Marketplace",
  },
  {
    to: "/investor",
    name: "Inversionista",
  },
  {
    to: "/politics",
    name: "Políticas de privacidad",
  },
];
const alert = {
  error: "error",
  success: "success",
};
const validationSchema = yup.object({
  telefono: yup
    .string("Ingrese su telefono")
    .required("El telefono es requerido"),
  nombre: yup.string("Ingrese su nombre").required("El nombre es requerido"),
  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email válido")
    .required("El email es requerido"),
  adicionales: yup
    .string("Ingrese su correo")
    .required("El campo es requerido"),
});
const initialValues = {
  nombre: "",
  acepto: false,
  adicionales: "",
  email: "",
  telefono: "",
};
export const Footer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dataAlert, setDataAlert] = useState("");

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const res = await request.form.postContact({
        ...values,
        acepto: values.acepto ? 1 : 0,
      });
      if (res.code === "ERR_BAD_REQUEST") {
        setDataAlert("error");
      } else {
        setDataAlert("success");
      }
      setOpenCollapse(true);
      setLoading(false);
    },
  });

  const { values, handleChange, touched, errors, handleSubmit, setValues } =
    formik;

  const { nombre, acepto, adicionales, email, telefono } = values;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className={classes.container}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4} md={3}>
          <Typography className={classes.title}>Quiénes Somos</Typography>
          <Typography className={classes.p}>
            Especialistas en Inversion Inmobiliaria, Formación de
            Inversionistas, Brokers y Planner. Analisis de datos, alianzas
            comerciales, gestión hipotecaria, sugerencias de inversión y
            representación de nuestros inversionistas. La comunidad de
            inversionistas inmobiliaria mas grande de Chile.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography className={classes.title}>
            Ubicación y contacto
          </Typography>
          <Typography className={classes.p}>
            Badajoz 100
            Santiago, Las Condes.
            <br />
            <br />
            TELÉFONO:
            <br />
            <a
              href="tel:+56990966974"
              style={{ textDecoration: "none", color: "white" }}
            >
              + 56 9 9096 6974
            </a>
            <br />
            <br />
            E-MAIL
            <br />
            <a
              href="mailto:contacto@iavchile.cl"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              contacto@iavchile.cl
            </a>
          </Typography>

          <a
            href="https://www.facebook.com/iavrealty"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
            rel="noreferrer"
          >
            <IconButton aria-label="facebook">
              <Facebook style={{ color: "white", fontSize: "40px" }} />
            </IconButton>
          </a>
          <a
            href="https://www.instagram.com/goinmobiliario_/"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
            rel="noreferrer"
          >
            <IconButton aria-label="instagram">
              <Instagram style={{ color: "white", fontSize: "40px" }} />
            </IconButton>
          </a>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography className={classes.title}>Información</Typography>

          <Box style={{ marginTop: "10px" }}>
            {links.map((item) => (
              <li style={{ listStyle: "none" }} key={item.to}>
                <Link className={classes.list} to={item.to}>
                  {item.name}
                </Link>
              </li>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box className={classes.containerWs}>
            <Home style={{ color: "white", fontSize: "120px" }} />
            <Typography className={classes.contact}>
              Oferte su inmueble
              <br /> con nosotros
              <br />
              <Typography
                component={"span"}
                className={classes.contactBtn}
                onClick={handleClickOpen}
              >
                Ofertar
              </Typography>
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box className={classes.ws}>
              <a
                href="https://api.whatsapp.com/send?phone=56990966974"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Box className={classes.wsText} style={{}}>
                  <WhatsApp style={{ color: "white", fontSize: "50px" }} />
                </Box>

                <RecordVoiceOver style={{ color: "white", fontSize: "40px" }} />
                <Typography className={classes.btnws}>Contáctanos</Typography>
              </a>
            </Box>
            <Box style={{margin:"10px"}}>
              <AddToHomeScreen/>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.titleDialog}>
          Oferte su inmueble con nosotros
        </DialogTitle>
        <DialogContent>
          <MuiTextField
            id="nombre"
            name="nombre"
            value={nombre}
            onChange={handleChange}
            label="Nombre"
            variant="outlined"
            margin="normal"
            error={touched.nombre && Boolean(errors.nombre)}
            helperText={touched.nombre && errors.nombre}
            fullWidth
          />
          <MuiTextField
            id="email"
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            fullWidth
          />
          <MuiTextField
            id="telefono"
            margin="normal"
            name="telefono"
            value={telefono}
            onChange={handleChange}
            label="Télefono"
            variant="outlined"
            error={touched.telefono && Boolean(errors.telefono)}
            helperText={touched.telefono && errors.telefono}
            fullWidth
          />
          <MuiTextField
            id="adicionales"
            margin="normal"
            name="adicionales"
            value={adicionales}
            onChange={handleChange}
            label="Adicionales"
            variant="outlined"
            multiline
            error={touched.adicionales && Boolean(errors.adicionales)}
            helperText={touched.adicionales && errors.adicionales}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                value={acepto}
                onChange={(e) =>
                  setValues({ ...values, acepto: e.target.checked })
                }
                name="acepto"
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
              />
            }
            label="Acepto recibir información sobre ofertas inmobiliarias"
          />

          <Collapse
            in={openCollapse}
            style={{
              marginTop: "30px",
            }}
          >
            <Alert
              severity={alert[dataAlert]}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenCollapse(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>{alert[dataAlert]}</AlertTitle>
              {dataAlert === "success"
                ? "Formulario enviado correctamente"
                : "Ocurrio un error al enviar la Solicitud"}
            </Alert>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            style={{
              color: "white",
              background: "#00B2F2",
            }}
          >
            {loading ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              "Subscribir"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
