import React from "react";
import { CardActionArea, Typography, Box, makeStyles, Chip } from "@material-ui/core";
import { baseURL } from "../../api/base";
import img3 from "../../images/no-image.jpg";

const useStyles = makeStyles((theme) => ({
  cardImage: {
    height: 180,
    width: 280,
    borderRadius: 15,
  },
  cardDescription1: {
    position: "absolute",
    height: "auto",
    width: 260,
    borderRadius: 15,
    background: "#03B0EE",
    bottom: "5%",
    padding: 20,
    color: "white",
  },
  cardDescription2: {
    position: "absolute",
    height: "auto",
    width: 260,
    borderRadius: 15,
    border: "2px solid #03B0EE",
    background: "white",
    color: "black",
    bottom: "5%",
    padding: 20,
  },
  availability: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
  },
  property1: {
    fontFamily: "Montserrat",
    fontSize: "22px",
    letterSpacing: "-1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
    color: "#03B0EE",
  },
  property2: {
    fontFamily: "Montserrat",
    fontSize: "22px",
    letterSpacing: "-1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
    color: "white",
  },
  info: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left",
  },
}));

export const InmobiCard = ({
  image,
  id = 0,
  titulo,
  direccion,
  habitaciones,
  img_principal,
  entrega,
  bano,
  precio_de_alquiler,
  precio_de_venta,
  tipo_de_moneda,
  status,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Box
      style={{
        position: "relative",
        height: "355px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardActionArea onClick={onClick} className={classes.cardImage}>
      
        <img
          src={
            img_principal
              ? baseURL +
                "storage" +
                img_principal?.slice(6, img_principal.lenght)
              : img3
          }
          alt={
            img_principal
              ? baseURL +
                "storage" +
                img_principal?.slice(6, img_principal.lenght)
              : img3
          }
          style={{ width: "100%", height: "100%", borderRadius: 15 }}
        />
      </CardActionArea>
      <CardActionArea
        onClick={onClick}
        className={
          id % 2 === 0 ? classes.cardDescription2 : classes.cardDescription1
        }
      >
        <Typography className={classes.availability}>
          {entrega ? entrega : "Entrega inmediata"}
        </Typography>
        <Typography
          className={id % 2 === 0 ? classes.property1 : classes.property2}
        >
          {titulo ? titulo.slice(0, 13) : "Inmueble"} / <br />{" "}
          {direccion ? direccion.slice(0, 22) : "Vicuña Mackena 1500"}
        </Typography>
        <Typography className={classes.info}>
          {bano?.nombre > 1 ? `${bano?.nombre} Baños` : `${bano?.nombre} Baño`}{" "}
          <br />
          {habitaciones?.nombre} Dormitorios <br /> Desde{" "}
          {precio_de_venta && Math.round(precio_de_venta)}{" "}
          {precio_de_alquiler && Math.round(precio_de_alquiler)}{" "}
          {tipo_de_moneda && tipo_de_moneda?.nombre}
        </Typography>
        <Chip label={status} variant="outlined" color="primary"/>
      </CardActionArea>
    </Box>
  );
};
