import { request } from "../base";

const endpoints = {
  get: "/api/obtenerunidadesquery?search=",
  getPropiedades: "/api/obtenerepropiedad",
  getNegocios: "/api/obtenertnegocio",
  getPais: "/api/obtenerpaises",
  getCiudades: "/api/obtenerciudades",
  getEstados: "/api/obtenerestados",
  getTipoInmueble: "/api/obtenertipoinmueble",
  getBanos: "/api/obtenerbanos",
  getHabitaciones: "/api/obtenerhabitaciones",
  getUnidades: "/api/obtenerunidades",
  getUnidadesDestacadas: "/api/obtenerunidadesdestacadas",
  entregaFuturo: "/api/obtenerentregafuturoquery?search=",
  entregaInmediata: "/api/obtenerentregainmediataquery?search=",
  oportunidades: "/api/obteneroportunidadesquery?search=",
  bonopie: "/api/obtenerbonopiequery?search=",
  canje: "/api/obtenercanjequery?search=",
};

const values = {
  valueStatePropiedad: "&estado=",
  valueStateNegocio: "&negocio=",
  valuePaises: "&paises=",
  valueEstados: "&estados=",
  valueCiudades: "&ciudades=",
  valueTipoInmueble: "&tipo=",
  valueHabitaciones: "&habitaciones=",
  valueBanos: "&banos=",
};

const queryFormat = (url, payload, nameEndpoint, page) => {
  // ?search=&paises=[3]&estados=[7]&ciudades=[104]&tipo=[1]&habitaciones=[1]&banos=[1]
  let arrayValues = [];
  let query = endpoints[nameEndpoint] ? endpoints[nameEndpoint] : url;
  let arrayId = [];
  if(payload.textsearch){
    const text = payload.textsearch || "";
    delete payload.textsearch;
    query = query + text;
  }

  arrayValues = Object.entries(payload);
  for (let i = 0; i < arrayValues.length; i++) {
    if (arrayValues[i][1].length > 0 || arrayValues[i][1] > 0) {
      if (Array.isArray(arrayValues[i][1])) {
        arrayId = arrayValues[i][1].map((item) => item.id);
        query += values[arrayValues[i][0]] + `[${arrayId.toString()}]`;
      } else {
        query +=
          values[arrayValues[i][0]] + `[${arrayValues[i][1].toString()}]`;
      }
    }
  }
  //console.log(text + query + "&page=" + page);
  return query + "&page=" + page;
};

export const get = async (payload = "", nameEndpoint, page = 1) => {
  /* console.log("hola");
  console.log(payload);
  console.log("fin payload"); */
  const res = await request.get(
    queryFormat(endpoints.get, payload, nameEndpoint, page)
  );
  return res.data;
};

export const getUnidades = async (id) => {
  const res = await request.get(endpoints.getUnidades + "/" + id);
  return res.data;
};
export const getPropiedades = async () => {
  const res = await request.get(endpoints.getPropiedades);
  return res.data;
};
export const getNegocios = async () => {
  const res = await request.get(endpoints.getNegocios);
  return res.data;
};
export const getPais = async () => {
  const res = await request.get(endpoints.getPais);
  return res.data;
};

export const getCiudades = async (id = 0) => {
  const res = await request.get(
    id > 0 ? endpoints.getCiudades + `/${id}` : endpoints.getCiudades
  );
  return res.data;
};
export const getEstados = async (id) => {
  const res = await request.get(
    id > 0 ? endpoints.getEstados + `/${id}` : endpoints.getEstados
  );
  return res.data;
};

export const getTipoInmueble = async () => {
  const res = await request.get(endpoints.getTipoInmueble);
  return res.data;
};

export const getHabitaciones = async () => {
  const res = await request.get(endpoints.getHabitaciones);
  return res.data;
};
export const getBanos = async () => {
  const res = await request.get(endpoints.getBanos);
  return res.data;
};

export const getUnidadesDestacadas = async () => {
  const res = await request.get(endpoints.getUnidadesDestacadas);
  return res.data;
};
