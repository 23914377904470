import React, { useState } from "react";
import { CardActionArea, Typography, Box, makeStyles, Chip, CardMedia, CardContent, Card } from "@material-ui/core";
import { baseURL } from "../../api/base";
import img3 from "../../images/no-image.jpg";
import imgLoading from "../../images/cargando-propiedad.png"

const useStyles = makeStyles((theme) => (
  {
    CardMedia: {
    borderRadius: '22px 22px 0 0',
  },
  cardDescription1: {
    height: 300,
    borderRadius: '0 0 22px 22px',
    background: "#03B0EE",
    bottom: "5%",
    padding: 20,
    color: "white",
  },
  cardDescription2: {
    height: 300,
    borderRadius: '0 0 22px 22px',
    border: "2px solid #03B0EE",
    background: "white",
    color: "black",
    bottom: "5%",
    padding: 20,
  },
  availability: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
  },
  property1: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "-1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
    color: "#03B0EE",
  },
  property2: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "-1px",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left",
    color: "white",
  },
  info: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left",
  },
}
));

function separators(num = "") {
  if(num != null){
    /* console.log(num);
    console.log(typeof num); */
    num = num.toString();
    var num_parts = num.split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num_parts.join(",");
  }
}

export const InmobiCardV2 = ({
  id = 0,
  titulo,
  direccion,
  habitaciones,
  img_principal,
  entrega,
  bano,
  precio_de_alquiler,
  precio_de_venta,
  tipo_de_moneda,
  status,
  onClick,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  }

  return (
    <Box>

        <Card sx={{ maxWidth: 345 }} style={{borderRadius:22, padding:6}}>
          <CardActionArea onClick={onClick} >
            <CardMedia
            className={classes.CardMedia}
              component="img"
              height="200"
              image={ loading ? 
                imgLoading:
                (img_principal
                  ? baseURL +
                    "storage" +
                    img_principal?.slice(6, img_principal.lenght)
                  : img3) 
                  
              }
              alt={
                img_principal
                  ? baseURL +
                    "storage" +
                    img_principal?.slice(6, img_principal.lenght)
                  : img3
              }
              onLoad={handleImageLoad}
            />
            <CardContent className={
              id % 2 === 0 ? classes.cardDescription2 : classes.cardDescription1
            }>

              
              <Typography className={classes.availability}>
              {precio_de_alquiler ? "Arriendo" : entrega}
              </Typography>

              <Typography
                className={id % 2 === 0 ? classes.property1 : classes.property2}
              >
                {titulo ? titulo.slice(0, 30) : "Inmueble"}{" "}
              </Typography>

              <Typography style={{textAlign:"left"}}>Direccion:</Typography>
              <Typography
                className={id % 2 === 0 ? classes.property1 : classes.property2}
              >
              {direccion ? direccion.slice(0, 50) : "Vicuña Mackena 1500"}
              </Typography>

              <Typography className={classes.info}>
                {bano?.nombre > 1 ? `${bano?.nombre} Baños` : `${bano?.nombre} Baño`}{" "}
                <br />
                {habitaciones?.nombre} Dormitorios <br /> Desde{" "}
                <b style={{fontSize:'18px'}}>
                {precio_de_venta && separators(precio_de_venta)}{" "}
                {precio_de_alquiler && separators(precio_de_alquiler)}{" "}
                {tipo_de_moneda && tipo_de_moneda?.nombre}
                </b>
              </Typography>
              
              <Chip label={status} variant="outlined" color="primary"/>
            </CardContent>
          </CardActionArea>
        </Card>
    </Box>
  );
};
