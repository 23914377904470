import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
//const MarketplaceScreen = lazy(() => import("../screens/marketplace"));
const AdviserScreen = lazy(() => import("../screens/adviser"));
const InvestorScreen = lazy(() => import("../screens/investor"));
const SearchScreen = lazy(() => import("../screens/search"));
const PropertyScreen = lazy(() => import("../screens/property"));
const PrivacyPolicy = lazy(() => import("../screens/politics"));

export const AppRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<MarketplaceScreen />} /> */}
      <Route path="/" element={<SearchScreen />} />
      <Route path="/property/:id" element={<PropertyScreen />} />
      <Route path="/adviser" element={<AdviserScreen />} />
      <Route path="/investor" element={<InvestorScreen />} />
      <Route path="/politics" element={<PrivacyPolicy/>} />
    </Routes>
  );
};
