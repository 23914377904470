import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import {MobileScreenShare} from "@material-ui/icons";

const AddToHomeScreen = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('Usuario aceptó la instalación de la aplicación');
        } else {
          //console.log('Usuario canceló la instalación de la aplicación');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <Button onClick={handleInstallClick} variant="contained" startIcon={<MobileScreenShare />}>
    Instalar Web como PWA
    </Button>
  );
};

export default AddToHomeScreen;
