import { Fragment, Suspense } from "react";
import { Nav, Footer, Loader } from "./Components";
import { AppRouter } from "./router";
import { BrowserRouter } from "react-router-dom";

import Alert from '@mui/material/Alert';

const App = () => {



  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>

        {/* <Alert variant="filled" severity="warning" style={{position:"fixed", width:"100%",zIndex:"999"}}>
          Estamos trabajando en el desarrollo de esta web
        </Alert> */}
        
          <Nav />
          <AppRouter />
          <Footer />
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
