import React from "react";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import img from "../../images/happy-family-sitting-on-couch-in-living-room.jpg";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
    [theme.breakpoints.up("md")]: {
      padding: 30,
    },
    [theme.breakpoints.up("lg")]: {
      padding: 40,
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "left",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      marginTop: 0,
      lineHeight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      marginTop: 8,
      lineHeight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      marginTop: 10,
      lineHeight: "30px",
    },
  },
  price: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "left",
    textTransform: "none",
    color: "#F50057",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      marginTop: 0,
      lineHeight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      marginTop: 8,
      lineHeight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
      marginTop: 10,
      lineHeight: "30px",
    },
  },
  subtitle: {
    fontFamily: "Montserrat",
    color: "#9E9E9E",
    textTransform: "none",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 8,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 10,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 14,
    },
  },
  options: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 8,
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 10,
      marginTop: 8,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 12,
      marginTop: 10,
    },
  },
  location: {
    fontFamily: "Montserrat",
    textAlign: "left",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 8,
      marginTop: 6,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 10,
      marginTop: 8,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 12,
      marginTop: 10,
    },
  },
  accion: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    padding: "2px 5px 2px 5px",
    width: 80,
    borderRadius: 5,
    background: "#6DC770",
    color: "white",
    textTransform: "none",
    fontSize: 10,
  },
}));
export const CardOffers = () => {
  const classes = useStyles();

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        background: "#FFF9F9",
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: 20,
      }}
    >
      <Box style={{ width: "100%", height: "100%", display: "flex" }}>
        <Box
          style={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px 0px 0px 20px",
            width: "50%",
          }}
        />
        <Box className={classes.container}>
          <Typography
            className={classes.accion}
            variant="button"
            display="block"
            gutterBottom
          >
            For sale
          </Typography>
          <Typography
            className={classes.location}
            variant="caption"
            display="block"
            gutterBottom
          >
            <LocationOn
              color="secondary"
              style={{ fontSize: "14px", position: "absolute" }}
            />
            <span style={{ marginLeft: "20px" }}>Iquique, Ch</span>
          </Typography>
          <Typography
            className={classes.title}
            variant="button"
            display="block"
            gutterBottom
          >
            Hermosa villa en la playa
          </Typography>
          <Typography
            className={classes.price}
            variant="button"
            display="block"
            gutterBottom
          >
            $10.000
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="button"
            display="block"
            gutterBottom
          >
            sdvsdfadk skdmaskldm skadmaslkdmaskl asdkasmdkasmFDsdfsdfsd sfsdfsd
            sdfasdfsd sdfsdf
          </Typography>
          <Divider />
          <Typography
            className={classes.options}
            variant="caption"
            display="block"
            gutterBottom
          >
            500 m² &nbsp;&nbsp;&nbsp; 2 rooms &nbsp;&nbsp;&nbsp; 1 baths
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
