import { request } from "../base";

const endpoints = {
  postInversionInmediata: "/api/forminversionistainmediata",
  postInversionFuturo: "/api/forminversionistafuturo",
  postCotizacion: "/api/formcotizacion",
  postContact: "/api/contacto",
};

export const postCotizacion = async (payload) => {
  const res = await request.post(endpoints.postCotizacion, payload);
  return res.data;
};

export const postContact = async (payload) => {
  const res = await request
    .post(endpoints.postContact, payload)
    .then((response) => response)
    .catch((error) => error);
  return res.data;
};

export const postInversionista = async (payload, type = 1) => {
  const res = await request
    .post(
      type ? endpoints.postInversionInmediata : endpoints.postInversionFuturo,
      payload
    )
    .then((response) => response)
    .catch((error) => error);
  return res.data ? res.data : res;
};
