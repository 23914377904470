import React from "react";
import {
  Box,
  CssBaseline,
  TextField,
  Grid,
  makeStyles,
} from "@material-ui/core";
import img from "../../images/happy-family-sitting-on-couch-in-living-room.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
  },
  image: {
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  containerCardTitleBlack: {
    letterSpacing: "8px",
    fontWeight: "700",
    color: "black",
    fontSize: "32px",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
    },
  },
}));

export const SectioneFive = () => {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
        <div className={classes.paper}>
          <h1 className={classes.containerCardTitleBlack}>CONTACTO</h1>
          <Box style={{ marginTop: 40 }} />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TextField
              label={"Nombre"}
              //   onChange={handleChange}
              size={"small"}
              name="nombre"
              variant="outlined"
              fullWidth
              //   value={tipologia}
            />
            <Box style={{ marginTop: 20 }} />
            <TextField
              label={"Email"}
              //   onChange={handleChange}
              size={"small"}
              name="email"
              fullWidth
              variant="outlined"
              //   value={tipologia}
            />
            <Box style={{ marginTop: 20 }} />
            <TextField
              label={"Asunto"}
              //   onChange={handleChange}
              size={"small"}
              name="asunto"
              fullWidth
              variant="outlined"
              //   value={tipologia}
            />
            <Box style={{ marginTop: 20 }} />
            <TextField
              id="outlined-multiline-static"
              label="Mensaje"
              multiline
              minRows={4}
              fullWidth
              variant="outlined"
            />
            <div className="btn">
              <button className="bn54">
                <span className="bn54span">Enviar</span>
              </button>
            </div>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
