import React from "react";
import "./style.css";
import {
  // Button,
  // TextField,
  makeStyles,
  // Grid,
  Box,
  // Typography,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  col: {
    padding: "0 1em 1em 1em",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  iconCard: {
    width: "250px",
    height: "100%",
    padding: "0em 1.5em 2em 1.5em",
    transition: "0.5s",
    position: "relative",
    zIndex: 2,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: "2em 1.5em",
    },
  },
  iconWrapper: {
    background: "black",
    position: "relative",
    margin: "auto",
    fontSize: "50px",
    height: "2.5em",
    width: "2.5em",
    color: "#ffffff",
    borderRadius: "50%",
    display: "grid",
    placeItems: "center",
    /* transition: 0.5s; */
    zIndex: 3,
  },
}));
export const IconCard = ({
  title,
  icon,
  description,
  link,
  href,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.col}>
      <Box className={classes.iconCard}>
        <Box className={classes.iconWrapper}>
          <i className="material-icons" style={{ fontSize: "50px" }}>
            {icon}
          </i>
        </Box>
        <h3>{title}</h3>
        <p>{description}</p>
        <Box className={"btn"}>
          <a
            href={href ? link : "#"}
            target={href ? "_blank" : ""}
            rel="noreferrer"
          >
            <button className="bn54" onClick={link} disabled={disabled}>
              <span className="bn54span">Ver</span>
            </button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};
