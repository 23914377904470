import axios from "axios";

export const baseURL = "https://admin.goinmobiliario.cl/";
//export const baseURL = "https://admin.goinmobiliario.cl/";
//export const baseURL = "https://slimy-berries-cross-190-108-79-150.loca.lt/";
//export const baseURL = "http://127.0.0.1:8000/";
export const request = axios.create({
	baseURL: baseURL,
});

