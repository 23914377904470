import React from "react";
import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    maxHeight: 389,
    borderRadius: 8,
    boxShadow: "2px 8px 15px rgba(0, 0, 0, 0.15)",
  },
  media: {
    width: 300,
    height: 330,
  },
  content: {
    padding: 5,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textTransform: "none",
    margin: 0,
  },
  subtitle: {
    fontFamily: "Montserrat",
    color: "#9E9E9E",
  },
});
export const Card = ({ title, image }) => {
  const classes = useStyles();
  return (
    // <div className="card">
    //   <img
    //     src={image}
    //     alt={image}
    //     style={{
    //       height: "100%",
    //       width: "100%",
    //     }}
    //   />
    //   <div className="card-title">
    //     <h1>{title}</h1>
    //   </div>
    // </div>
    <MuiCard className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
          title="Contemplative Reptile"
        />
        <CardContent className={classes.content}>
          <Typography
            className={classes.title}
            variant="button"
            display="block"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="caption"
            display="block"
            gutterBottom
          >
            1300 Inmuebles
          </Typography>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};
