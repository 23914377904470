import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../images/logo_go.png";
import "./style.css";

export const Nav = () => {
  let location = useLocation();
  let navigate = useNavigate();
  return (
    <nav className="navbar">
      <div
        className="navbar-overlay"
        onClick={() => document.body.classList.toggle("open")}
      />
      <button
        type="button"
        className="navbar-burger"
        onClick={() => document.body.classList.toggle("open")}
      >
        <span className="material-icons">menu</span>
      </button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          type="button"
          style={{
            borderTop: location.pathname === "/" && `10px solid #00B2F2`,
          }}
          onClick={() => navigate("/", { replace: false })}
        >
          <img
          src={logo}
          alt={"logo"}
          style={{ width: "220px", height: "220px", marginRight: "10px" }}
        />
        <h1 className="navbar-title">
          {/* IAV <br /> <span>REALTY</span> */}
        </h1>
        </button>
        
      </div>

      <nav className="navbar-menu">
        {/* style={{ borderTop: "10px solid #00B2F2" }} */}
        <button
          type="button"
          style={{
            borderTop: location.pathname === "/" && `10px solid #00B2F2`,
          }}
          onClick={() => navigate("/", { replace: false })}
        >
          Marketplace 
        </button>
        {/* <button
          type="button"
          style={{
            borderTop:
              location.pathname === "/marketplace" && `10px solid #00B2F2`,
          }}
          onClick={() => navigate("/marketplace", { replace: false })}
        >
          Marketplace
        </button> */}
        <button
          type="button"
          style={{
            borderTop:
              location.pathname === "/investor" && `10px solid #00B2F2`,
          }}
          onClick={() => navigate("/investor", { replace: false })}
        >
          Inversionista
        </button>
        {/* <button type="button" onClick={() => window.open("https://corredores.iavchile.com/", "_blank")}>
          <span style={{color:"white"}}>Corredor</span>
        </button> */}
        <button
          type="button"
          style={{
            borderTop: location.pathname === "/adviser" && `10px solid #00B2F2`,
          }}
          // onClick={() => navigate("/adviser", { replace: false })}
        >
          <a
            href={"https://broker.goinmobiliario.cl/"}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            Broker
          </a>
        </button>
      </nav>
    </nav>
  );
};
