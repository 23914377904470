import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "#FAFAFA",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    padding: "25px",
    minWidth: "200px",
    width: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.up("md")]: {
    },
  },
  title: {
    fontFamily: "Montserrat",
    color: "#00B2F2",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
}));

export const PropertyCard = ({ icon, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      {icon}
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};
